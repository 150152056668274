<template>
  <div>
    <div style="height: 20px"></div>
    <!--详情-->
    <el-row>
      <el-col :span="16">
        <el-form v-loading="loading" size="medium" ref="form" :model="form" :label-width="this.env.label_width">
          <!--仅运营平台-->
          <el-form-item label="适用范围">
            <el-radio v-model="form.range_type" :label="2"> 仅练市集 </el-radio>
            <el-radio v-model="form.range_type" :label="1">
              全平台可用
            </el-radio>
          </el-form-item>
          <!--仅运营平台-end-->

          <el-form-item label="优惠券名称">
            <el-input v-model="form.coupon_title"></el-input>
          </el-form-item>
          <el-form-item label="优惠券封面">
            <Qnupload v-model="form.cover" :sum="1" :isclipper="true" :fixedNumber="[2.83, 1]" />
            <span class="form-tip">图片尺寸1020*360 或 宽:高≈2.83:1</span>
          </el-form-item>
          <el-form-item label="满减条件(￥)">
            <el-input-number v-model="form.full_money" :step="0.01" :min="0" :max="999999"
              step-strictly></el-input-number>
            <br /><span class="form-tip">满足所设置金额才可以使用本优惠券</span>
          </el-form-item>
          <el-form-item label="抵扣金额(￥)">
            <el-input-number v-model="form.coupon_money" :step="0.01" :min="0" :max="999999"
              step-strictly></el-input-number>
          </el-form-item>
          <el-form-item label="有效期限(天)">
            <el-input-number v-model="form.validity_time" :step="1" :min="0" :max="999" step-strictly></el-input-number>
            <br /><span class="form-tip">从用户获取之日开始计算，0 永久有效</span>
          </el-form-item>

          <el-form-item label="门店范围">
            <el-radio v-model="form.store_range" :label="2">
              指定门店
            </el-radio>
            <el-radio v-model="form.store_range" :label="1">
              所有门店
            </el-radio>
          </el-form-item>
          <div class="appoint" v-show="form.store_range === 2">
            <el-form-item label="指定门店">
              <el-select v-model="form.store_s" multiple filterable placeholder="请选择门店" style="width: 100%">
                <!-- remote
                :remote-method="get_store_list"
                :loading="select_loading" -->
                <el-option v-for="(item, key) in store_list" :key="key" :label="item.store_name" :value="item.store_uuid">
                  <span style="color: #8492a6; font-size: 13px">
                    【{{ item.company_name }}】
                  </span>
                  <span>{{ item.store_name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门店品类">
              <MotionCategorySelect :motionCategoryList.sync="form.store_motion_category_two" />
              <el-select v-model="form.store_operate_tag" multiple filterable placeholder="请关联运营标签" style="width: 100%">
                <el-option v-for="(item, key) in operate_list" :key="key" :label="item.name" :value="item.id">
                  <span style="color: #8492a6; font-size: 13px">
                    【{{ item.name }}】
                  </span>
                  <span>{{ item.description }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item label="课程范围">
            <el-radio v-model="form.lesson_range" :label="2">
              指定课程
            </el-radio>
            <el-radio v-model="form.lesson_range" :label="1">
              所有课程
            </el-radio>
          </el-form-item>
          <div class="appoint" v-show="form.lesson_range === 2">
            <el-form-item label="指定课程">
              <el-select v-model="form.lesson_s" multiple filterable placeholder="请选择课程" style="width: 100%">
                <el-option v-for="(item, key) in lesson_list" :key="key" :label="item.lesson_name"
                  :value="item.lesson_uuid">
                  <span style="color: #8492a6; font-size: 13px">
                    【{{ item.company_name }}】
                  </span>
                  <span>{{ item.lesson_name }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课程品类">
              <MotionCategorySelect :motionCategoryList.sync="form.lesson_motion_category_two" />
              <MotioneffectSelect :motionffectList.sync="form.lesson_target_category_zero" />
              <el-select v-model="form.lesson_operate_tag" multiple filterable placeholder="请关联运营标签" style="width: 100%">
                <el-option v-for="(item, key) in operate_list" :key="key" :label="item.name" :value="item.id">
                  <span style="color: #8492a6; font-size: 13px">
                    【{{ item.name }}】
                  </span>
                  <span>{{ item.description }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <el-form-item label="教练范围">
            <el-radio v-model="form.coach_range" :label="2">
              指定教练
            </el-radio>
            <el-radio v-model="form.coach_range" :label="1">
              所有教练
            </el-radio>
          </el-form-item>
          <div class="appoint" v-show="form.coach_range === 2">
            <el-form-item label="指定教练">
              <el-select v-model="form.coach_s" multiple filterable placeholder="请选择教练" style="width: 100%">
                <el-option v-for="(item, key) in coach_list" :key="key" :label="item.user_nick" :value="item.coach_uuid">
                  <span style="color: #8492a6; font-size: 13px">
                    【{{ item.company_name }}】
                  </span>
                  <span>{{ item.user_nick }}</span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教练标签">
              <div>
                <el-select @change="add_coach_tag" v-model="coach_lesson_tag" filterable placeholder="请选择标签"
                  style="width: 100%">
                  <el-option v-for="(item, key) in coach_tag_list" :key="key" :label="item.tag_name"
                    :value="item.tag_name + '|' + item.tag_colour">
                  </el-option>
                </el-select>
              </div>
              <div class="form-tag_colour-div">
                <el-tag v-for="(item, key) in this.form.coach_tag_s" :key="key" class="form-tag_colour-tag" effect="dark"
                  closable size="small" @close="tagClose(item, 'coach_tag_s')" :color="item.tag_colour">
                  {{ item.tag_name }}
                </el-tag>
              </div>
              <span class="form-tip">指定教练和教练标签同时满足才生效，不选择则不限制</span>
            </el-form-item>
          </div>

          <el-form-item>
            <el-button v-if="is_auth('coupon.library.isadd')" size="medium" type="primary" @click="save">保存
            </el-button>
            <el-button size="medium" @click="isreturn">返回</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Qnupload from "@/components/Qnupload";
import MotionCategorySelect from "@/components/MotionCategorySelect";
import MotioneffectSelect from "@/components/MotioneffectSelect";
export default {
  components: {
    Qnupload,
    MotionCategorySelect,
    MotioneffectSelect,
  },
  data() {
    return {
      loading: false,
      select_loading: false,

      store_list: [], // 门店列表
      select_store_tag: "", // 选择的门店标签
      store_tag_list: [], // 门店标签

      lesson_list: [], // 课程列表
      select_lesson_tag: "", // 选择的课程标签
      lesson_tag_list: [], // 课程标签

      coach_list: [], // 教练列表
      coach_lesson_tag: "", // 选择的教练标签
      coach_tag_list: [], // 教练标签

      form: {
        range_type: 2, // 优惠券范围 1 全平台，2练市集

        coupon_title: "", // 优惠券名称
        cover: "", // 优惠券封面
        full_money: 0, // 满减条件
        coupon_money: 0, // 优惠金额
        validity_time: 30, // 有效期限

        store_range: 2, // 门店范围
        store_s: [], // 可用门店信息
        store_tag_s: [], // 可用门店标签

        lesson_range: 2, // 课程范围
        lesson_s: [], // 可用课程信息
        lesson_tag_s: [], // 可用课程标签

        coach_range: 2, // 教练范围
        coach_s: [], // 可用教练信息
        coach_tag_s: [], // 可用教练标签
        store_motion_category_two: [], //可用门店运动品类二级
        store_operate_tag: [], //可用门店运营标签
        lesson_motion_category_two: [], //可用课程运动品类二级
        lesson_target_category_zero: [], //可用课程可用课程运动效果
        lesson_operate_tag: [], //可用课程运营标签
      },
      operate_list: [], // 运营标签
    };
  },
  // 创建
  created() {
    this.get_store_list(null); // 获取可选门店
    this.get_lesson_list(null); // 获取可选课程
    this.get_coach_list(null); // 获取可选教练
    this.get_store_tag(); // 获取门店标签
    this.get_lesson_tag(); // 获取课程标签
    this.get_coach_tag(); // 获取教练标签
    this.get_operate_list(null); // 获取运营标签
  },
  // 安装
  mounted() { },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取门店列表
    get_store_list(key) {
      // if (key === '') return false;
      let postdata = {
        api_name: "coupon.library.getusablestore",
        token: this.Tool.get_l_cache("token"),
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.store_list = json.data;
        } else {
          // this.store_list = []
        }
      });
    },
    get_operate_list(key) {
      // if (key === '') return false;
      let postdata = {
        api_name: "operate.tag.getlist",
        token: this.Tool.get_l_cache("token"),
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.operate_list = json.data.list;
        } else {
          // this.store_list = []
        }
      });
    },
    // 课程门店标签
    get_store_tag() {
      let postdata = {
        api_name: "store.store.gettag",
        token: this.Tool.get_l_cache("token"),
        tag_type: 1,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.store_tag_list = json.data;
        } else {
          this.store_tag_list = [];
          this.Tool.errormes(json);
        }
      });
    },
    // 添加门店标签
    add_store_tag() {
      console.log(this.select_store_tag);
      let select_tag = this.select_store_tag.split("|");
      let tag = {
        tag_name: select_tag[0],
        tag_colour: select_tag[1],
      };
      if (!this.tag_exist(tag.tag_name, "store_tag_s")) {
        this.form.store_tag_s.push(tag);
      }
      this.select_store_tag = "";
    },

    // 获取课程列表
    get_lesson_list(key) {
      // if (key === '') return false;
      let postdata = {
        api_name: "coupon.library.getusablelesson",
        token: this.Tool.get_l_cache("token"),
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.lesson_list = json.data;
        } else {
          // this.lesson_list = []
        }
      });
    },
    // 获取课程标签
    get_lesson_tag() {
      let postdata = {
        api_name: "lesson.lesson.gettag",
        token: this.Tool.get_l_cache("token"),
        tag_type: 1,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.lesson_tag_list = json.data;
        } else {
          this.lesson_tag_list = [];
          this.Tool.errormes(json);
        }
      });
    },
    // 添加课程标签
    add_lesson_tag() {
      let select_tag = this.select_lesson_tag.split("|");
      let tag = {
        tag_name: select_tag[0],
        tag_colour: select_tag[1],
      };
      if (!this.tag_exist(tag.tag_name, "lesson_tag_s")) {
        this.form.lesson_tag_s.push(tag);
      }
      this.select_lesson_tag = "";
    },

    // 获取教练列表
    get_coach_list(key) {
      // if (key === '') return false;
      let postdata = {
        api_name: "coupon.library.getusablecoach",
        token: this.Tool.get_l_cache("token"),
        key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.coach_list = json.data;
        } else {
          // this.coach_list = []
        }
      });
    },
    // 获取教练标签
    get_coach_tag() {
      let postdata = {
        api_name: "coach.companycoach.gettag",
        token: this.Tool.get_l_cache("token"),
        tag_type: 1,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.coach_tag_list = json.data;
        } else {
          this.coach_tag_list = [];
          this.Tool.errormes(json);
        }
      });
    },
    // 添加教练标签
    add_coach_tag() {
      let select_tag = this.coach_lesson_tag.split("|");
      let tag = {
        tag_name: select_tag[0],
        tag_colour: select_tag[1],
      };
      if (!this.tag_exist(tag.tag_name, "coach_tag_s")) {
        this.form.coach_tag_s.push(tag);
      }
      this.coach_lesson_tag = "";
    },

    // 判断标签是否存在
    tag_exist(tag_name, key) {
      if (this.form[key].length > 0) {
        for (let v of this.form[key]) {
          if (tag_name == v.tag_name) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    // 删除标签
    tagClose(tag, key) {
      this.form[key].splice(this.form[key].indexOf(tag), 1);
    },
    //保存
    save() {
      let postdata = {
        api_name: "coupon.library.isadd",
        token: this.Tool.get_l_cache("token"),
      };
      Object.assign(postdata, this.form);
      postdata["lesson_motion_category_two"] =
        this.form.lesson_motion_category_two.map((item) => item.id);
      postdata["lesson_target_category_zero"] =
        this.form.lesson_target_category_zero.map((item) => item.id);
      postdata["store_motion_category_two"] =
        this.form.store_motion_category_two.map((item) => item.id);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.isreturn(); // 返回到列表
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 返回
    isreturn() {
      this.$router.go(-1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appoint {
  border-left: 10px solid #409eff;
}
</style>
